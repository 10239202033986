<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header header-elements-inline">

        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Doctor information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body" >

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Doctor Name" v-if="doctor" v-model="doctor.name" autocomplete="off">
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="doctor" v-model="doctor.street"  ></textarea>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="City" maxlength="50" autocomplete="off" v-if="doctor" v-model="doctor.city">
          </div>

          <label class="col-md-1 col-form-label">PIN:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Pincode" maxlength="7" v-if="doctor" v-model="doctor.pin">
          </div>

          <label class="col-md-1 col-form-label">Area:</label>
          <div class="col-md-2">
            <select class="form-control" v-if="doctor" v-model="doctor.area_id">
              <option value="1" selected>Primary</option>
              <option v-for="area in areas" v-bind:value="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Qualification:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Qualification" maxlength="50" autocomplete="off" v-if="doctor" v-model="doctor.qualification">
          </div>

          <label class="col-md-1 col-form-label">Speciality:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Speciality" maxlength="50" v-if="doctor" v-model="doctor.speciality">
          </div>

          <label class="col-md-1 col-form-label">Class:</label>
          <div class="col-md-2">
            <input class="form-control" v-if="doctor" v-model="doctor.class" placeholder="Class" maxlength="50" autocomplete="off"/>
          </div>

        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">DOB:</label>
          <div class="col-md-4">
            <input type="date" class="form-control" v-if="doctor" v-model="doctor.dob" />
          </div>

          <label class="col-md-1 col-form-label">DOW:</label>
          <div class="col-md-5">
            <input type="date" class="form-control" v-if="doctor" v-model="doctor.dow" />
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Spouse Name:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Spouse Name" v-if="doctor" v-model="doctor.spouse_name" autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">DOB:</label>
          <div class="col-md-2">
            <input type="date" class="form-control" placeholder="Spouse DOB" v-if="doctor" v-model="doctor.spouse_dob" autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">Profession:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Spouse Profession" maxlength="50" v-if="doctor" v-model="doctor.spouse_profession" autocomplete="off">
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Email:</label>
          <div class="col-md-4">
            <input type="email" class="form-control" placeholder="Doctor's Email" maxlength="100" autocomplete="off" v-if="doctor" v-model="doctor.email">
          </div>

          <label class="col-md-1 col-form-label">Mobile:</label>
          <div class="col-md-2">
            <input type="tel" class="form-control" placeholder="Mobile" maxlength="10" v-if="doctor" v-model="doctor.mobile">
          </div>

          <label class="col-md-1 col-form-label">Whatsapp:</label>
          <div class="col-md-2">
            <input type="tel" class="form-control" placeholder="Whatsapp" maxlength="10" v-if="doctor" v-model="doctor.whatsapp">
          </div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-primary legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'DoctorForm',
  store,
  data() {
    return {
      doctor: JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}'),
      areas : [],
    }
  },
  component: {

  },
  props: {
    mydoctor: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}')
    }
  },
  beforeMount () {
    this.doctor = this.mydoctor; // save props data to itself's data and deal with tms
  },
  created () {
    this.$data.doctor = JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}')
  },
  mounted () {
    this.loadAreas();
    $('.form-control-select2').select2()
    $('#txtname').focus();
  },
  methods: {
    closeWindow(){
      this.doctor = JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}');
      this.$emit("doctor_window_closed");
    },
    loadAreas () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };
      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.areas = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/areas`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data) ){
            self.$data.areas = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      })

    },
    saveDocument(){

      const self = this;

      self.$data.doctor.type = parseInt(self.$data.doctor.type);
      if(self.$data.doctor.name.toString().length< 5){
        alert("Invalid Name");
        return;
      }else if( parseInt(self.$data.doctor.area_id) < 1 ){
        alert("Invalid Area selected");
        return;
      }

      self.$data.doctor.area_id = parseInt(self.$data.doctor.area_id);
      self.$data.doctor.opening_balance =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.doctor.opening_balance));

      const requestOptions = {
        method:  (self.$data.doctor.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.doctor)
      };



      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.$emit('doctor_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
